import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { WaxApi } from './api';
import { useTable } from 'react-table';

const LeaderboardContainer = styled.div`
  background-color: #f0f0f0;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  width: 80%;
  margin: 0 auto;
`;

const TableWrapper = styled.table`
  border-collapse: collapse;
  width: 100%;
  margin: 0 auto;
  border: 1px solid black;
  th, td {
    border: 1px solid black;
    padding: 8px;
    text-align: center;
  }
  th {
    background-color: #282c34;
    color: white;
  }
`;

export const Table = () => {
  const [apiData, setApiData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const waxApi = new WaxApi();
      try {
        const data = await waxApi.getTableRows();
        console.log('Response Data:', data);
        if (data && data.rows) {
          const rowsWithNumbers = data.rows.map((row: any, index: number) => ({
            ...row,
            rowNumber: index + 1,
          }));
          const sortedRows = rowsWithNumbers.sort((a: { resources: number; }, b: { resources: number; }) => b.resources - a.resources);
          setApiData(sortedRows);
        } else {
          setApiData([]);
        }
      } catch (error: any) {
        console.error('Error:', error.message);
      }
    };

    // Fetch data initially
    fetchData();

    // Set up a timer to fetch data every 30 seconds
    const fetchInterval = setInterval(() => {
      fetchData();
    }, 30000);

    // Cleanup the interval when the component unmounts
    return () => {
      clearInterval(fetchInterval);
    };
  }, []);

  const columns = useMemo(
    () => [
      {
        Header: 'Account',
        accessor: (row: any) => row.account,
      },
      {
        Header: 'Resources',
        accessor: 'resources',
      },
      {
        Header: 'Last Use',
        accessor: 'last_use',
      },
      {
        Header: 'Position',
        accessor: 'rowNumber',
      },
    ],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable(
    {
      columns,
      data: apiData,
    }
  );

  return (
    <LeaderboardContainer>
      <TableWrapper {...getTableProps()}>
        <thead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <th {...column.getHeaderProps()}>{column.render('Header')}</th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map(row => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map(cell => {
                  return (
                    <td {...cell.getCellProps()}>{cell.column.Header === 'Position' ? row.index + 1 : cell.render('Cell')}</td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </TableWrapper>
    </LeaderboardContainer>
  );
};
