export class WaxApi {
    async getTableRows() {
        try {
          const apiUrl = 'https://wax.blokcrafters.io/v1/chain/get_table_rows'; // Replace with the actual endpoint URL
      
          // Define the payload data by merging default values and provided parameters
          const payload = {
            "code": "cntrc.parsec",
            "scope": "cntrc.parsec",
            "table": "leaderboard",
            "lower_bound": "",
            "upper_bound": "",
            "index_position": 1,
            "key_type": "i64",
            "limit": "2000",
            "reverse": false,
            "json": true,
            "show_payer": false
        };
      
          // Set the headers
          const headers = new Headers({
            'Content-Type': 'application/json',
            'Origin': 'http://localhost', // Set the origin of your website
            // Add other headers as needed
          });
      
          // Create the fetch options
          const options: RequestInit = {
            method: 'POST',
            headers,
            body: JSON.stringify(payload),
            keepalive: true, // Set a timeout of 15 seconds (adjust as needed)

          };
      
          // Make the fetch request
          const response = await fetch(apiUrl, options);
          // Check if the response status is OK
          if (response.ok) {
            const responseData = await response.json(); // Parse the JSON response
            return responseData;
          } else {
            throw new Error(`Request failed with status ${response.status}`);
          }
        } catch (error) {
          throw error;
        }
      }
}

