import styled from 'styled-components'

const HeaderWrapper = styled.header`
    background-color: #282c34;
    min-height: 20vh;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    color: white;
`

export const Header = () => {
    return (
        <HeaderWrapper className="">
            Few Parsec Away LeaderBoard
        </HeaderWrapper>
    )
    }